<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting contact_profile">
            <button class="close_btn modal-window-close-button" @click="closeModal()">
                <i class="fas fa-long-arrow-alt-left"></i>
            </button>
            <button type="button" class="close_btn modal-window-close-button contact-name-button">
                {{ contact.name }}
            </button>
            <div class="profile_sidebar">
                <div class="profile_header">
                    <!-- <button class="option" @click="launchPadToggle"><img src="@/assets/images/launchpad.svg"></button> -->
                    <div class="contact_thumb" v-click-outside="closeTemperatureOption">
                        <img :src="contact.photo" alt="">
                        <span :class="`temp ${contact.temperature ? contact.temperature.toLowerCase() : 'warm'}`" @click="temperatureOption($event)">
                            <ul class="row_option">
                                <li @click="changeTemperature(contact.id, 'cold')">Cold</li>
                                <li @click="changeTemperature(contact.id, 'warm')">Warm</li>
                                <li @click="changeTemperature(contact.id, 'hot')">Hot</li>
                            </ul>
                        </span>
                        <svg class="tag" :class="{ active: contact.starred }" @click="toggleStarred($event, contact.id)" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.47 18.8"><path class="cls-1" d="M13.09,6.25l-.43-1.09c-.35-.88-.79-2-1.17-3a1.64,1.64,0,0,0-3,0c-.38,1-.82,2.1-1.17,3L6.85,6.25s-3.36.29-3.95.37C1.25,6.84,1.09,7.8,1.09,7.8a1.58,1.58,0,0,0,.46,1.6h0l2.47,2,.9.74-.29,1.13c-.24.92-.54,2.1-.8,3.1a1.64,1.64,0,0,0,.62,1.73h0a1.63,1.63,0,0,0,1.83.06h0L9,16.47l1-.63,1,.63,2.7,1.72a1.63,1.63,0,0,0,1.84-.05h0a1.64,1.64,0,0,0,.62-1.73c-.26-1-.56-2.18-.8-3.1L15,12.18l.91-.74,2.47-2h0a1.63,1.63,0,0,0-.94-2.88h0l-3.2-.2Z" transform="translate(-0.26 -0.4)"/></svg>
                        <button type="button" @click="contactPhotoModal = !contactPhotoModal"><i class="fas fa-pen"></i></button>
                    </div>
                    <div>
                        <!-- <h6>{{ contact.member_id }}</h6> -->
                        <h4>{{ contact.name }}</h4>
                        <!-- <div v-show="!infoEdit" class="status_bar m-0"><span :class="contact.temperature.toLowerCase()"></span></div> -->
                        <ul class="connectivity">
                            <!-- <li v-tooltip="`Click to Email`" position="right"><a :href="`mailto:${contact.email}`"><img src="@/assets/images/email.svg"></a></li> -->
                            <li v-tooltip="`Click to Email`" position="right"><a @click="handleConnection('email')"><img src="@/assets/images/email.svg"></a></li>
                            <li v-tooltip="`Click to Message`" position="right">
                                <a @click="showMsgType = !showMsgType">
                                    <img src="@/assets/images/sms-circle.svg">
                                    <div class="dropdown_wpr" :class="{'active' : showMsgType}">
                                        <ul>
                                            <li @click="handleConnection('sms')">SMS</li>
                                            <li @click="handleConnection('portal-msg')">Portal Chat</li>
                                        </ul>
                                    </div>
                                </a>
                            </li>
                            <li v-tooltip="`Click to Call`" position="right"><a :href="`tel:${contact.number}`"><img src="@/assets/images/phone-alt.svg"></a></li>
                            <li v-tooltip="`Open Launchpad`" position="right"><a @click="launchPadToggle"><img src="@/assets/images/launchpad.svg"></a></li>
                            <li v-tooltip="`Edit Profile`" position="right"><a @click="editProfile = true"><img src="@/assets/images/pencil.svg"></a></li>
                            <li v-tooltip="`Delete Contact`" position="right" class="danger"><a @click="deleteContact(contact.id)"><img src="@/assets/images/trash.svg"></a></li>
                        </ul>
                    </div>
                    <button type="button" class="more_info" @click="personalInfo = !personalInfo">{{personalInfo ? 'Hide' : 'Show'}} client info<i :class="`fas ${personalInfo ? 'fa-angle-up' : 'fa-angle-down'}`"></i></button>
                </div>
                <div class="profile_topbar">
                    <!-- <ul class="profile_info">
                        <li>
                            <h5>E-mail :</h5>
                            <div>
                                <p>{{ contact.email }}</p>
                                <div class="btn_confirm" v-if="contact.is_confirm == 0">
                                    <button class="unconfirm">Unconfirmed</button>
                                </div>
                            </div>
                            <span class="tooltip">{{contact.email}}</span>
                        </li>
                        <li>
                            <h5>Phone :</h5>
                            <p>{{ contact.number }}</p>
                        </li>
                        <li>
                            <h5>Address:</h5>
                            <p>{{ contact.address ? contact.address : 'Unavailable'}} {{ contact.city}} {{ contact.state}} {{ contact.zipcode}}, {{ contact.country}}</p>
                        </li>
                    </ul> -->
                    <div class="extra_card" v-if="personalInfo">
                        <div class="title"><img src="@/assets/images/member-id.svg">Client ID:</div>
                        <div class="card_info">
                            <h3>{{ contact.member_id }}
                                <button type="button" class="copy" @click="copyMemberId()"><i class="fas fa-copy"></i></button>
                            </h3>
                        </div>
                    </div>
                    <div class="extra_card" v-if="personalInfo">
                        <div class="title"><img src="@/assets/images/calendar-alt.svg">Client Since:</div>
                        <div class="card_info">
                            <p>{{contact.date_created}}</p>
                            <h5>{{ getNumberOfDays(contact.date_created) }}</h5>
                        </div>
                    </div>
                    <div class="extra_card" v-if="personalInfo">
                        <div class="title"><img src="@/assets/images/client.svg">Client Birthday:</div>
                        <div class="card_info">
                            <p>{{ contact.birthdate ? getRemainingDays(contact.birthdate) + ' to go' : '' }}</p>
                            <h5>{{ contact.birthdate ? moment(contact.birthdate).format('ll') : 'Missing Birthday!' }}</h5>
                        </div>
                    </div>
                    <div class="extra_card" v-if="personalInfo">
                        <div class="title"><img src="@/assets/images/client.svg">Client Info:</div>
                        <div class="card_info">
                            <p class="pl-4"><img src="@/assets/images/marker.svg">{{ contact.address ? contact.address : 'No Address'}} {{ contact.city}} {{ contact.state}} {{ contact.zipcode}}, {{ contact.country}}</p>
                            <p class="pl-4"><img src="@/assets/images/email.svg">{{ contact.email }}</p>
                            <p class="pl-4"><img src="@/assets/images/phone-alt.svg">{{ contact.number }}</p>
                        </div>
                    </div>
                    <div class="extra_card">
                        <div class="title"><img src="@/assets/images/note.svg">Latest Notes: <button type="button" class="add_btn m-0 ml-auto" @click="handleAddNote()"><i class="fas fa-plus"></i>Add</button></div>
                        <template  v-if="notes.data && notes.data.length">
                            <swiper :slidesPerView="'auto'" :spaceBetween="0" :navigation='true' class="note_listing">
                                <swiper-slide v-for="(note, n) in notes.data" :key="n">
                                    <div class="card_info">
                                        <p><em>{{ note.note }}</em></p>
                                        <!-- <h6>{{ moment(note.created_at).format('MM/DD/YYYY')}}</h6> -->
                                        <h6>{{ moment(note.created_at).format('ll')}}</h6>
                                        <!-- <button type="button" class="edit"><i class="far fa-edit"></i></button> -->
                                    </div>
                                </swiper-slide>
                            </swiper>
                        </template>
                        <div class="card_info" v-else>
                            <p><em>No notes available</em></p>
                        </div>
                    </div>
                    <div class="extra_card">
                        <div class="title"><img src="@/assets/images/tags-alt.svg">Tags: <button type="button" class="add_btn m-0 ml-auto" @click="addTag = true;"><i class="fas fa-plus"></i>Add</button></div>
                        <div class="card_info tags-wpr">
                            <quote-loader v-if="contactTagsLoader" :show-quote="false" />
                            <template v-else>
                                <ul class="tags" v-if="contactTags && contactTags.length">
                                    <li :id="`contact-profile-tag-${t}`" v-for="(tag, t) in contactTags" :key="t">
                                        <h4 :style="{ background: getTagBackground(tag.tag_type) }">{{tag.name}}
                                            <span class="remove_btn" @click="handleRemoveTag(tag, t)">
                                                <i class="fas fa-times"></i>
                                            </span>
                                        </h4>
                                    </li>
                                </ul>
                                <div class="no_content" v-else>
                                    <img src="@/assets/images/empty_state.svg">
                                    <h5 class="m-0">No Tags</h5>
                                </div>
                            </template>
                        </div>
                    </div>
                    <div class="extra_card">
                        <div class="title"><img src="@/assets/images/all.svg">Custom Fields: <button type="button" class="add_btn m-0 ml-auto" @click="addCustomField = true;"><i class="fas fa-plus"></i>Add</button></div>
                        <div class="card_info">
                            <ul class="custom_fields" v-if="customFields && customFields.length">
                                <li v-for="(field, f) of customFields" :key="f">
                                    <span>
                                        {{ field.name }} <br />
                                        <span class="custom-field-ans">{{ contact.custom_field[field.id] ? contact.custom_field[field.id] : '-' }}</span>
                                    </span>
                                </li>
                            </ul>
                            <div class="no_content" v-else>
                                <img src="@/assets/images/empty_state.svg">
                                <h5 class="m-0">No Custom Fields</h5>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="profile_stat">
                        <ul class="action_status">
                            <li>
                                <div class="score_circle" style="width:44px; height:44px; padding: 2px;">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ stats.email_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="44" height="44">
                                        <circle cx="21" cy="21" r="20" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202" :stroke-dashoffset="202 - (stats.email_rate * 2)"/>
                                    </svg>
                                </div>
                            </li>
                            <li>
                                <h2>{{ stats.email_sent }}</h2>
                                <p>Email Sent</p>
                            </li>
                            <li>
                                <h2>{{ stats.email_opened }}</h2>
                                <p>Opened</p>
                            </li>
                        </ul>
                        <ul class="action_status">
                            <li>
                                <div class="score_circle" style="width:44px; height:44px; padding: 2px;">
                                    <div class="inner_circle">
                                        <div class="score" style="transform: scale(0.8);">{{ stats.sms_rate }}<span>%</span></div>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="44" height="44">
                                        <circle cx="21" cy="21" r="20" stroke-linecap="round" fill="none" stroke-width="2px" stroke="#2F7FED" :stroke-dasharray="202"  :stroke-dashoffset="202 - (stats.sms_rate * 2)"/>
                                    </svg>
                                </div>
                            </li>
                            <li>
                                <h2>{{ stats.sms_sent }}</h2>
                                <p>SMS Sent</p>
                            </li>
                            <li>
                                <h2>{{ stats.sms_reply }}</h2>
                                <p>Replied</p>
                            </li>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="tabs_content">
                <button type="button" class="toggle_bar" @click="toggleProfilebar"><img src="@/assets/images/bar-left.svg" alt=""></button>
                <swiper :slidesPerView="'auto'" :spaceBetween="30" :navigation='true' class="tab_slider topTabs">
                    <swiper-slide><span @click="profileTab = 1" :class="{ active: profileTab === 1 }">Details</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 2" :class="{ active: profileTab === 2 }">Lifecycle</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 3" :class="{ active: profileTab === 3 }">Notes</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 4" :class="{ active: profileTab === 4 }">Forms</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 5" :class="{ active: profileTab === 5 }">Files</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 6" :class="{ active: profileTab === 6 }">Rewards</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 7" :class="{ active: profileTab === 7 }">Chats</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 8" :class="{ active: profileTab === 8 }">Emails</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 9" :class="{ active: profileTab === 9 }">Tracking</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 10" :class="{ active: profileTab === 10 }">Habits</span></swiper-slide>
                    <swiper-slide><span @click="profileTab = 11" :class="{ active: profileTab === 11 }">Workouts</span></swiper-slide>
                </swiper>
                <div class="profileTab_content">
                    <detail-component v-if="profileTab == 1" :contact="contact" />
                    <lifecycle-component v-if="profileTab == 2" :contact="contact" />
                    <note-component v-if="profileTab == 3" :contact="contact" />
                    <form-component v-if="profileTab == 4" :contact="contact" />
                    <file-component v-if="profileTab == 5" :contact="contact" />
                    <reward-component v-if="profileTab == 6" :contact="contact" />
                    <chat-component v-if="profileTab == 7" :contact="contact" :handle-launch-pad="launchPadToggle" />
                    <email-component-local v-if="profileTab == 8" :contact="contact" />
                    <progress-tracking-component v-if="profileTab == 9" :contact="contact" />
                    <habit-tracking-component v-if="profileTab == 10" :contact="contact" />
                    <workouts-component v-if="profileTab == 11" :contact="contact" />
                    <div class="edit_modal" v-if="contactPhotoModal" @click.self="contactPhotoModal = false">
                        <div class="edit_section p-0">
                            <div class="form_body">
                                <h2 class="title_header">Upload Image<button class="close_btn" @click="contactPhotoModal = false"><i class="fas fa-times"></i></button></h2>
                                <div class="form_content">
                                    <div class="setting_wpr">
                                        <div class="upload_image mt-3">
                                            <image-upload v-model="form.photo" :crop="true" image-type="profile-pic" :is-avatar="true" />
                                        </div>
                                        <div class="action_wpr p-0 mb-3">
                                            <button type="button" :disabled="photoUpdateLoader" class="btn cancel_btn" @click="contactPhotoModal = false">Cancel</button>
                                            <button type="button" :disabled="photoUpdateLoader" @click="handleContactPhotoUpdate()" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="photoUpdateLoader"></i> {{ photoUpdateLoader ? 'Updating' : 'Update' }}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <launch-pad v-model="lauchPad" :contact="contact" />
        <send-email-component v-model="confirmationEmail" :contacts="contactId" :is-confirmation="confirmationEmail" />
        <add-custom-field v-model="addCustomField" />
        <edit-profile v-model="editProfile" :contact="contact" />
        <add-tag v-model="addTag" :contacts="[contact.id]" />
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import SwiperCore, { Navigation } from 'swiper'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

    const DetailComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Detail'))
    const LifecycleComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Lifecycle'))
    const NoteComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Note'))
    const FormComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Form'))
    const FileComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/File'))
    const RewardComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Reward'))
    const ChatComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Chat'))
    const EmailComponentLocal = defineAsyncComponent(() => import('@/pages/contact/components/profile/Email'))
    const ProgressTrackingComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/ProgressTracking'))
    const HabitTrackingComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/HabitTracking'))
    const WorkoutsComponent = defineAsyncComponent(() => import('@/pages/contact/components/profile/Workouts'))
    const LaunchPad = defineAsyncComponent(() => import('@/pages/contact/components/LaunchPad'))
    const SendEmailComponent = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/SendEmail'))
    const ImageUpload = defineAsyncComponent(() => import('@/components/image-library/ImageUpload'))
    const AddCustomField = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/AddCustomField'))
    const EditProfile = defineAsyncComponent(() => import('@/pages/contact/components/profile/components/EditProfile'))
    const AddTag = defineAsyncComponent(() => import('@/pages/contact/components/bulk-action/AddTag'))

    import moment from 'moment'
    import Swal from 'sweetalert2'
    import Helper from '@/utils/Helper'

    import 'swiper/swiper-bundle.min.css'
    SwiperCore.use([Navigation])

    export default {
        data () {
            return {
                profileTab: 1,
                moment,
                lauchPad: false,
                confirmationEmail: false,
                contactId: [],
                contactPhotoModal: false,
                moreTab: false,
                personalInfo: false,
                form: {
                    contact_id: 0,
                    photo: '',
                },
                addCustomField: false,
                customFields: [],
                editProfile: false,
                contactTags: [],
                showMsgType: false,
                addTag: false,
            };
        },

        props: {
            modelValue: Boolean,
            contact: Object,
            updateSelectedContact: Function,
            isIdList: {
                type: Boolean,
                default: false,
            },
            tab: {
                type: Number,
                default: 0,
            }
        },

        emits: ['update:modelValue'],

        watch: {
            modelValue (val) {
                if (val) {
                    const vm      = this;
                    vm.profileTab = 1;

                    setTimeout(function () {
                        vm.getAllDetails();
                    }, 100);
                }
            },

            contact: {
                handler (contact) {
                    const vm = this;

                    vm.contactId       = [contact.id];
                    vm.form.contact_id = contact.id;
                    vm.form.photo      = contact.photo;
                },
                deep: true,
            },

            profileTab (tab) {
                localStorage.setItem('contact_profile_tab', tab);
            },

            customFieldsAll () {
                const vm = this;

                vm.customFields = JSON.parse(JSON.stringify(vm.customFieldsAll));
                vm.customFields = vm.customFields.reverse().slice(0, 5);
            },

            selectedTags (val) {
                const vm       = this;

                vm.contactTags = [];
                vm.contactTags = vm.filterTags(val);
            },

            tagsGroup: {
                handler (tags) {
                    const vm       = this;

                    vm.contactTags = [];
                    vm.contactTags = vm.filterTags(vm.selectedTags);
                },
                deep: true
            },

            addTag (val) {
                const vm = this;

                if (!val) {
                    vm.getAllDetails();
                }
            }
        },

        components: {
            DetailComponent,
            LifecycleComponent,
            NoteComponent,
            FormComponent,
            FileComponent,
            RewardComponent,
            ChatComponent,
            EmailComponentLocal,
            ProgressTrackingComponent,
            HabitTrackingComponent,
            WorkoutsComponent,
            LaunchPad,
            SendEmailComponent,
            ImageUpload,
            Swiper,
            SwiperSlide,
            AddCustomField,
            EditProfile,
            AddTag,
        },

        computed: {
            ...mapState({
                stats: state => state.contactModule.stats,
                photoUpdateLoader: state => state.contactModule.photoUpdateLoader,
                notes: state => state.contactModule.notes,
                loader: state => state.contactModule.contactDetailsLoader,
                contactTagsLoader: state => state.contactModule.contactTagsLoader,
                customFieldsAll: state => state.contactModule.customFieldsAll,
                selectedTags: state => state.contactModule.selectedTags,
                tagsGroup: state => state.tagModule.tagsGroup,
            }),

            ...mapGetters({
                filterTags: 'tagModule/FILTER_TAGS_TYPE',
            })
        },

        mounted () {
            const vm = this;

            if (vm.contact && vm.contact.id) {
                vm.contactId       = [vm.contact.id];
                vm.form.contact_id = vm.contact.id;
                vm.form.photo      = vm.contact.photo;
            }

            vm.resetTimezoneByCountry([]);

            if (vm.tab) {
                vm.profileTab = vm.tab;
            }

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }

            setTimeout(function () {
                vm.resetDetail();
            }, 100);

            vm.getCustomFields({ active: 1, type: 'all' });
        },

        methods: {
            ...mapActions({
                getContactStats: 'contactModule/getContactStats',
                updateContactPhoto: 'contactModule/updateContactPhoto',
                markContactAsStarred: 'contactModule/markContactAsStarred',
                updateTemperature: 'contactModule/updateTemperature',
                getNotes: 'contactModule/getNotes',
                getContactRewardPoints: 'contactModule/getContactRewardPoints',
                getContactTags: 'contactModule/getContactTags',
                bulkDelete: 'contactModule/bulkDelete',
                getTags: 'tagModule/getTags',
                getCustomFields: 'contactModule/getCustomFields',
                removeTag: 'tagModule/removeTag',
            }),

            ...mapMutations({
                resetTimezoneByCountry: 'commonModule/SET_TIMEZONES_BY_COUNTRY',
            }),

            closeModal () {
                const vm = this;

                vm.contactPhotoModal = false;
                vm.$emit('update:modelValue', false);
            },

            getNumberOfDays (date) {
                const dayDiff = moment.duration(moment(date).diff(moment().startOf('day'))).asDays();

                return `${Math.abs(dayDiff) + 1} DAYS`;
            },

            launchPadToggle () {
                const vm = this;

                if (vm.contact.is_confirm == 0) {
                    const option = Helper.swalConfirmOptions('Unconfirmed email?', 'It looks your contact has not confirmed yet. Would you like to resend the confirmation email?', false)

                    Swal.fire(option).then((result) => {
                        if (result.isConfirmed) {
                            vm.confirmationEmail = true;
                        } else {
                            vm.lauchPad = true;
                        }
                    });
                } else {
                    vm.lauchPad = true;
                }
            },

            handleContactPhotoUpdate () {
                const vm = this;

                vm.updateContactPhoto(vm.form).then((result) => {
                    if (result) {
                        vm.contactPhotoModal = false;

                        if (vm.updateSelectedContact) {
                            const contact = JSON.parse(JSON.stringify(vm.contact));
                            contact.photo = vm.form.photo;

                            vm.updateSelectedContact(contact);
                        }
                    }
                });
            },

            temperatureOption (e) {
                e.stopPropagation();
                let el = e.currentTarget.querySelector('.row_option');
                if (el.classList.contains('active')) {
                    el.classList.remove('active');
                } else {
                    el.classList.add('active');
                }
            },

            closeTemperatureOption () {
                let el = document.querySelector('.contact_thumb .row_option');
                el.classList.remove('active');
            },

            changeTemperature (contact_id, temperature) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', `You want to change temperature to ${temperature}.`)
                option.preConfirm = function() { return vm.updateTemperature({ contact_id, temperature}) };

                Swal.fire(option);
            },

            toggleStarred (e, contact_id) {
                e.stopPropagation();

                const vm    = this;
                let element = e.currentTarget;
                let starred = 1;

                if (element.classList.contains('active')) {
                    element.classList.remove('active');
                    starred = 0;
                } else {
                    element.classList.add('active');
                    starred = 1;
                }

                vm.markContactAsStarred({ starred, contact_id }).then((result) => {
                    if (!result) {
                        if (starred == 1) {
                            element.classList.remove('active');
                        } else {
                            element.classList.add('active');
                        }
                    }
                });
            },

            getAllDetails () {
                const vm   = this;
                const id   = vm.contact.id ? vm.contact.id : '';
                let params = {
                    page: 1,
                    per_page: 2,
                    id,
                }

                vm.getContactStats(id);
                vm.getContactTags(id);
                vm.getContactRewardPoints(id);

                delete params.id;
                params.contact_id = id;

                vm.getNotes(params);
            },

            getRemainingDays (date) {
                let daysLeft = moment(moment(date))
                              .add( moment(moment().format("YYYY-MM-DD") )
                              .diff( moment(date), "years") + 1, "years" )
                              .diff( moment().format("YYYY-MM-DD"), "days" ) + ' Days';

                return daysLeft;
            },

            resetDetail () {
                const vm  = this;

                if (vm.customFieldsAll && vm.customFieldsAll.length) {
                    vm.customFields = JSON.parse(JSON.stringify(vm.customFieldsAll));
                    vm.customFields = vm.customFields.reverse().slice(0, 5);
                }

                if (vm.selectedTags.length) {
                    vm.contactTags = [];
                    vm.contactTags = vm.filterTags(vm.selectedTags);
                }
            },

            toggleProfilebar () {
                const ls_bar = document.querySelector('.profile_sidebar');

                if (ls_bar.classList.contains('show_m_sidebar')) {
                    ls_bar.classList.remove('show_m_sidebar');
                } else {
                    ls_bar.classList.add('show_m_sidebar');
                }
            },

            copyMemberId () {
                const vm = this;

                Helper.copyContent(vm.contact.member_id, 'Member id');
            },

            closeMoreTab () {
                const vm = this;

                vm.moreTab = false;
            },

            deleteContact (id) {
                const vm = this;
                const option = Helper.swalConfirmOptions('Are you sure?', "You will not be able to recover these contacts.", 'Delete');

                option.preConfirm = function() {
                    return vm.bulkDelete({ contacts: new Array(id.toString()), deleted_through: 'Contact Profile' }).then(() => {
                        vm.closeModal();
                    });
                }

                Swal.fire(option);
            },

            getTagBackground (type) {
                let color = '';

                if (type == 1) {
                    color = '#f2a31d'
                } else if (type == 2) {
                    color = '#2f7eed'
                } else if (type == 3) {
                    color = '#75D218'
                }

                return  color;
            },
            handleConnection (type) {
                const vm = this;
                vm.lauchPad = true;

                setTimeout(() => {
                    const typeItem = document.querySelector(`.modal .section_wpr ul li #${type}`);
                    typeItem.click();
                }, 1000);
            },

            handleAddNote () {
                const vm = this;

                vm.profileTab = 3;

                setTimeout(() => {
                    const targetEl = document.querySelector('.note_editor .placeholder');
                    targetEl.click();
                }, 2000);
            },

            handleRemoveTag (tag, index) {
                const vm = this;

                const option = Helper.swalConfirmOptions('Are You Sure?', "You want to remove tag for selected contact?")

                option.preConfirm = function() {

                    const form = {
                        tags_id: [tag.id],
                        contacts: [vm.contact.id],
                        action: '/contacts/remove-tag',
                        type: 'leads'
                    };

                    document.getElementById(`contact-profile-tag-${index}`).style.display = 'none';
                    return vm.removeTag(form).then(() => {
                        vm.getAllDetails();
                    });
                 };

                Swal.fire(option);
            },
        },
    }
</script>

<style scoped>
    .global_setting .tabs_content{
        padding: 35px 30px 0 30px;
        position: relative;
    }
    .global_setting .tabs_content .tab_sec{
        background: #fff;
        border: 1px solid #eee;
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 25px;
    }
    .global_setting .tabs_content .tab_sec li{
        flex-grow: 1;
        text-align: center;
    }
    .global_setting .tabs_content .tab_sec li.active{
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
    }
    .global_setting .tabs_content .tab_slider {
        border-bottom: 1px solid #eee;
        padding: 0;
        max-width: 1170px;
        margin: 0 auto 30px auto;
    }
    .global_setting .tabs_content .tab_slider .swiper-slide{
        /* flex-grow: 1; */
        text-align: center;
    }
    .global_setting .tabs_content .tab_slider .swiper-slide span{
        border: 0;
    }
    .global_setting .tabs_content .tab_slider .swiper-slide span.active{
        color: #2f7eed;
    }
    .tab_slider:after, .tab_slider:before{
        background: #fff;
        width: 15px;
        display: none;
    }
    .global_setting .tabs_content form > :deep(.action_wpr){
        margin: auto -30px 0 -30px;
        padding: 25px 30px;
    }
    :deep(.profile_notes .search_panel){
        background: #FFF;
        margin-left: 15px;
    }
    :deep(.no-result){
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 8px;
        margin-top: 30px;
        padding: 40px 25px;
    }
    .tab_sec li .row_option{
        top: 100%;
        right: -15px;
        transform: translateY(15px);
        padding: 6px 0;
        width: fit-content;
    }
    .tab_sec li .row_option:before {
        display: none;
        right: 30px;
        left: auto;
        top: -6px;
        transform: rotate(-45deg) scale(1);
    }
    .tab_sec li .row_option li{
        font-size: 11px;
        line-height: 13px;
    }
    .tab_sec li .row_option li:hover{
        background: #eee;
    }

    :deep(.proofs){
        padding: 0;
    }
    :deep(.report_section){
        padding: 40px 10px;
        max-height: 566px;
        overflow-y: scroll;
    }
    :deep(.report_section::-webkit-scrollbar) {
        width: 4px;
    }
    :deep(.report_section:hover::-webkit-scrollbar-thumb) {
        background-color: #e4e4e4;
        border-radius: 2px;
    }
    :deep(.habit_container .vc-container){
        background: transparent;
    }
    :deep(.habit_container .edit_section){
        background: #fff;
    }
    :deep(.section_header h2){
        font-size: 22px;
        line-height: 30px;
    }
    :deep(.habit_container .report_card h3){
        margin-top: 0;
    }

    .btn_confirm {
        display: flex;
        justify-content: flex-start;
    }

    .profile_info .btn_confirm button.unconfirm {
        border: 1px solid #9FB1CF;
        background: #fff;
        color: rgba(104, 129, 166, 0.9);
        font-weight: 400;
    }

    .profile_info .btn_confirm button {
        height: 20px;
        white-space: nowrap;
        padding: 0 10px;
        font-size: 10.5px;
        line-height: 13px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0;
        border-radius: 10px;
        margin: 2px 4px 0 0;
    }

    :deep(.upload_area) {
        height: 280px;
    }
    .contact_thumb .temp{
        position: absolute;
        left: 2px;
        bottom: 0;
        width: 13px;
        height: 13px;
        cursor: pointer;
        background-color: #eaeaea;
        border: 2px solid #fff;
        border-radius: 50%;
        box-shadow: 0 1px 8px rgba(0,0,0,0.3);
        z-index: 3;
    }
    .contact_thumb .temp.warm{
        background-color: #F2A31D;
    }
    .contact_thumb .temp.cold{
        background-color: #2f7eed;
    }
    .contact_thumb .temp.hot{
        background-color: #ff0000;
    }
    .contact_thumb .temp .row_option{
        left: 100%;
        right: auto;
        margin-left: 8px;
        z-index: 2;
    }
    .contact_thumb .temp .row_option li:nth-child(1){
        color: #2f7eed;
        font-weight: 500;
    }
    .contact_thumb .temp .row_option li:nth-child(2){
        color: #F2A31D;
        font-weight: 500;
    }
    .contact_thumb .temp .row_option li:nth-child(3){
        color: #ff0000;
        font-weight: 500;
    }

    .swiper-container {
        margin-left: 0;
    }

    .swiper-container-pointer-events {
        width: 100%;
    }

    .global_setting button.toggle_bar {
        position: fixed;
        z-index: 4;
    }

    :deep(.upload_image .tab_content) {
        height: auto;
    }

    .contact-name-button {
        left: 40px;
        width: 100%;
        background: transparent;
        border-radius: 0;
        text-align: left;
        justify-content: left;
        padding-left: 10px;
        line-height: 25px;
        font-weight: 500;
        display: none;
        z-index: 1;
    }
    .tab_slider :deep(.swiper-wrapper){
        border: 0;
    }
    .profile_topbar .extra_card .card_info .custom_fields li {
        border-radius: 5px;
        background: #f9f9f9;
        padding: 7px 12px;
        font-size: 12px;
        line-height: 17px;
        font-weight: 500;
        color: #121525;
    }
    .profile_topbar .extra_card .card_info .custom_fields li:not(:last-child){
        margin-bottom: 10px;
    }
    .global_setting .close_btn{
        left: 15px;
    }
    .global_setting button.contact-name-button{
        left: 45px;
    }
    .profile_sidebar .connectivity li a .dropdown_wpr:before{
        display: none;
    }
    .profile_sidebar .connectivity li a .dropdown_wpr ul li{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        padding: 5px 12px;
        white-space: nowrap;
    }

    :deep(.card_info.tags-wpr .quote_wpr) {
        min-height: 150px;
        max-height: 150px;
    }

    @media(max-width:1399px) {
        .global_setting button.contact-name-button{
            display: block;
        }
        .global_setting .tabs_content{
            padding: 50px 20px 0 20px;
        }
    }
</style>
